import styled from "@emotion/styled";
import Image from "gatsby-image";
import React from "react";
import Slider from "react-slick";
import useCarouselImages from "../hooks/useCarouselImages";

const Slide = styled(Image)`
  border: 4px solid white;
`;

const Carousel = () => {
  const images = useCarouselImages();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    pauseOnFocus: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {images.map(({ sharp: { fluid } }, i) => (
        <Slide key={fluid.src} fluid={fluid} alt="Dance students having fun." />
      ))}
    </Slider>
  );
};

export default Carousel;