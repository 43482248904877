import { graphql, useStaticQuery } from "gatsby";

const useCarouselImages = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { sourceInstanceName: { eq: "carousel" } }
        sort: { fields: relativePath }
      ) {
        nodes {
          sharp: childImageSharp {
            fluid(maxWidth: 550) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `);

  return data.allFile.nodes;
};

export default useCarouselImages;
