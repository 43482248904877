import { css } from '@emotion/react';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import styled from '@emotion/styled';

const StyledBackground = styled(BackgroundImage)`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Card = ({ children, to, fixed, className = '' }) => {
  const classes = `flex flex-col flex-nowrap justify-between border-black border bg-white rounded overflow-hidden ${className} `;
  return (
    <article
      className={classes}
      css={css`
        width: 100%;
        max-width: 350px;
        min-height: 500px;
      `}
    >
      <StyledBackground
        fixed={fixed}
        fadeIn={false}
        loading="eager"
        style={{
          width: '100%',
        }}
      />
      <div className="px-3 md:px-6 py-4 bg-white flex-grow">{children}</div>
      <div className="mx-auto px-6 py-4 bg-white">
        <Link
          to={to}
          className="hover:bg-highlight bg-gray-800 text-secondary inline-block rounded-full px-3 py-1 text-lg font-semibold"
          css={css`
            transition: background-color 0.25s ease;
            &:hover {
              transform: translateY(2px);
            }
            &:active {
              transform: translateY(4px);
            }
          `}
        >
          Learn more &rarr;
        </Link>
      </div>
    </article>
  );
};

export default Card;
