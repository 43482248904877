import styled from "@emotion/styled";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";
const ImageBackground = styled(BackgroundImage)`
  /* background-size: cover; */
  height: 10rem;
`;

const TextBox = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const BoxLabel = styled("div")`
  /* position: relative; */
  padding: 1.25rem;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  transition: background-color 0.4s ease;

  .boxhover:hover & {
    background-color: rgba(197, 48, 48, 0.9);
  }
`;

const ImageLink = styled(Link)`
  display: inline-block;
  overflow: hidden;
  border: 2px solid black;
  cursor: pointer;

  &:hover {
    transform: translateY(2px);
  }
  &:active {
    transform: translateY(4px);
  }
`;

export default ({ to, label, fluid }) => (
  <ImageLink
    to={to}
    className="group w-full text-white font-semibold rounded-full"
  >
    <ImageBackground fluid={fluid}>
      <TextBox className="text-xl tracking-widest boxhover">
        <BoxLabel className="font-display  rounded-full">
          {label} &rarr;
        </BoxLabel>
      </TextBox>
    </ImageBackground>
  </ImageLink>
);
