import styled from "@emotion/styled";

export default styled.hr`
  margin: 2rem 0;
  border: 0;
  height: 1px;
  width: 100%;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
`;
